import Vue from 'vue'
import App from './App.vue'
import router from './router/index';
import axios from 'axios'
import Vant from 'vant'
import '../node_modules/vant/lib/index.css'
import { Uploader,Popup } from 'vant';
import VueLogger from 'vuejs-logger';
// import VConsole from 'vconsole'
// var vConsole = new VConsole();

Vue.config.productionTip = false;

const isProduction = process.env.NODE_ENV === 'production';
const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

Vue.use(VueLogger, options);
// Vue.use(vConsole)

Vue.use(Vant).use(Uploader).use(Popup)
Vue.prototype.$axios = axios;


new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})

